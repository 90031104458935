import React from 'react';

type RailsSVGProps = {
	active: boolean,
}

export const RailsSVG = ({ active }: RailsSVGProps) => {
	const svgColor = active ? '#0056CF' : '#687887'

	return (
		<div className="filter-icon">
			<svg width="18" height="16" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.2969 0L15.566 1.19979L17.4054 1.15843L17.2257 0.0829274L15.2969 0Z" fill={svgColor} />
				<path d="M11.1235 2.56303L10.0469 1.36345L11.5272 0.660156L12.6039 1.77702L11.1235 2.56303Z" fill={svgColor} />
				<path d="M2.64597 22.1289H14.5788C14.5788 22.1289 11.9321 16.5859 12.6048 11.4981C13.2779 6.41024 17.6291 4.09358 20.0964 3.84543C22.5635 3.59708 23.9095 5.00366 23.9095 5.00366L24.448 4.25902C24.448 4.25902 20.904 0.949771 16.4179 1.3222C11.9318 1.69442 8.83663 4.42444 6.90778 7.73369C4.97871 11.0431 3.85719 13.3594 3.09471 16.8341C2.33223 20.3087 2.64597 22.1289 2.64597 22.1289Z" fill={svgColor} />
				<path d="M0.269108 16.5859L2.60185 16.7514L2.19795 18.9022L0 18.654L0.269108 16.5859Z" fill={svgColor} />
				<path d="M4.57625 10.4243L3.9481 12.0375L1.83984 11.4169L2.51262 9.67969L4.57625 10.4243Z" fill={svgColor} />
				<path d="M6.86488 6.49363L8.0761 5.17019L6.50607 4.21875L5.25 5.50083L6.86488 6.49363Z" fill={svgColor} />
				<path d="M21.4883 2.02569L21.5331 1.28125L23.0135 2.27384L22.7444 2.64605L21.4883 2.02569Z" fill={svgColor} />
				<path d="M21.2617 4.46875V5.08912L22.4729 5.21319V4.71668L21.2617 4.46875Z" fill={svgColor} />
				<path d="M17.9893 5.99715L17.4062 5.00456L18.6623 4.71484L18.8417 5.37678L17.9893 5.99715Z" fill={svgColor} />
				<path d="M15.5671 6.28906L16.4644 7.53L15.9259 8.39851L14.625 7.07507L15.5671 6.28906Z" fill={svgColor} />
				<path d="M13.1875 10.7982L13.7708 9.72266L15.0715 10.6327L14.7127 11.998L13.1875 10.7982Z" fill={svgColor} />
				<path d="M13.1444 13.4844L13.0547 14.9321L14.9387 15.6768L14.849 14.3115L13.1444 13.4844Z" fill={svgColor} />
				<path d="M14.1323 20.1026L13.6836 18.6133H15.6573L16.5097 20.2267L14.1323 20.1026Z" fill={svgColor} />
			</svg>
		</div>
	)
}
